/*-- Variables --*/
:root {
    //Themes colors
    --color-primary: #2f57ef;
    --color-secondary: #b966e7;
    --color-coral: #E9967A;
    --color-violet: #800080;
    --color-pink: #DB7093;

    //Then
    --color-heading: #192335;
    --color-body: #000;


    //Then
    --color-white: #ffffff;
    --color-white-off: #FFFFFFAB;
    --color-bodyest: #273041;


    // Then
    --color-dark: #27272E;
    --color-darker: #192335;
    --color-black: #111113;

    // Then
    --color-grey: rgba(207,207,207,.24);
    --color-grey2: #f4f5f7;


    // Then
    --color-gray: #A1A9AC;
    --color-gray-light: #F6F6F6;
    --color-gray-lighter: #ebebeb;


    // Then light Colors 
    --color-light: #F5F7FA;
    --color-lighter: #F2F5F9;

    // Then Opacity 
    --primary-opacity: #2f57ef21;
    --secondary-opacity: #b966e721;
    --coral-opacity: #E9967A21;
    --violet-opacity: #80008021;
    --pink-opacity: #DB709321;
    --white-opacity: #ffffff21;
    --danger-opacity: #FF000310;
    --warning-opacity: #FF8F3C10;
    --success-opacity: #3EB75E10;
    --black-opacity: rgba(0, 0, 0, 0.04);
    --heading-opacity: #19233550;



    // Then Card Background Color
    --color-card-1: #fffccf;
    --color-card-2: #ffedff;
    --color-card-3: #ffe8eb;
    --color-card-4: #e9f6ff;
    --color-card-5: #5024D1;
    --color-card-6: #3B3B46;



    // Then Notify Colors 
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;




    --color-primary-alt: #D1EBE6;
    --color-primary-light: #f1f7ff;
    --color-primary-lighter: #EEEEFF;
    --color-secondary-alt: #fef4f4;


    //Extra Colour 
    --color-extra: rgb(249, 249, 255);
    --color-extra2: #F9F9FF;
    --color-border: #e6e3f1;
    --color-border-2: #e6e3f14f;






    //Badge Card Background Color
    --color-badge-1: #303F5B;
    --color-badge-2: #F2C94C;
    
    
    


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;
    
    //Border-radius
    --radius-10: 10px;
    --radius-big: 16px;
    --radius-small: 6px;
    --border-width: 2px;
    --radius: 6px;


    //Font weight
    --f-light: 300;
    --f-regular: 400;
    --f-medium: 500;
    --f-semi-bold: 600;
    --f-bold: 700;
    --f-extra-bold: 800;
    --f-black: 900;


    //Shadows
    --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
    --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    --shadow-1: 0px 6px 34px rgba(215, 216, 222, 0.41);
    --shadow-slide-item: 0px 0 10px 0 rgba(215, 216, 222, 0.20);

    --shadow-2: 0 15px 34px 0 rgba(175, 181, 204, 0.32);
    --shadow-3: 0 5px 15px 0 rgba(85, 74, 101, 0.37);
    --shadow-5: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
    --shadow-7: 0px 15px 30px -2px rgba(0, 0, 0, 0.1);
    --shadow-8: -5px 18px 60px 0 rgba(210, 210, 234, 0.4);
    --shadow-9: 0 13px 48px 0 rgba(215, 216, 222, 0.44);
    --shadow-10: 0 13px 14px 0 rgba(129, 104, 145, 0.05);
    --shadow-11: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    
    //transition easing
    --transition: 0.3s;
    --transition-2: 256ms ease-in-out;
    --transition-3: transform .85s cubic-bezier(.23,1,.32,1),background .3s,border .3s,border-radius .3s,box-shadow .3s;
    --transition-4: all .25s cubic-bezier(.645,.045,.355,1);


    //Font Family
    --font-primary: 'Euclid Circular';
    --font-secondary: 'Euclid Circular';
    --font-alt: 'Source Serif Pro', serif; 


    //Fonts Size
    --font-size-b1: 24px;
    --font-size-b2: 18px;
    --font-size-b3: 16px;
    --font-size-b4: 12px;

    //Line Height
    --line-height-b1: 1.5; //24px
    --line-height-b2: 1.67; //18px
    --line-height-b3: 1.67; //16px
    --line-height-b4: 1; //12px

    // Heading Font 
    --h1: 52px; //52px 1.29
    --h2: 44px; //44px 1.23
    --h3: 34px; // 34px 1.14
    --h4: 30px; //30px  1.25
    --h5: 20px; //18px 1.24
    --h6: 16px; //20px 1.25
    --div-gap-1: 10px;


    //Gradient
    --gradient-1: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 255) 100%); //Section Shade
    --gradient-4: linear-gradient(180deg,#fff,#f6f9ff); //Section Shade
    --gradient-2: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(240, 242, 255) 100%); //Bottom Shade
    --gradient-3: linear-gradient(90deg, #8DA9FC 0%, #B48DD5 100%); //Bottom Shade
    --gradient-4: linear-gradient(180deg, #EFF1FF 0%, #FFFFFF 100%);
    --gradient-5: linear-gradient(rgb(239, 241, 255) 0%, rgb(239, 241, 255) 100%);
    --gradient-6: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(251, 199, 226, 0.15), rgba(220, 217, 254, 0.15));
    --gradient-7: linear-gradient(218.15deg, var(--color-secondary) 0%, var(--color-primary) 100%);
    --gradient-8: linear-gradient(270deg, #9e77ff 0%, #4460ff 100%);
    --gradient-9: linear-gradient(180deg, #6827FF 0%, #E3F0FF 100%);
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$lgtolp-device: 'only screen and (min-width: 1200px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';




